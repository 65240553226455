import { message } from "antd";
import { useStore } from "react-redux";
import RequestService from "../../service";
export const SYS_MGT_MENU_LIST_INIT = "SYS_MGT_MENU_LIST_INIT"; // 菜单列表初始化
export const SYS_MGT_MENU_EXPAND_MENU_ITEM = "SYS_MGT_MENU_EXPAND_MENU_ITEM"; // 展开菜单项
export const SyS_MGT_MENU_TABLE_LOADING = "SyS_MGT_MENU_TABLE_LOADING"; // table loading树形
export const SYS_MGT_MENU_EXPANDED_ROW_KEYS = "SYS_MGT_MENU_EXPANDED_ROW_KEYS"; // 展开的行key
export const SYS_MGT_MENU_MODAL_INFO = "SYS_MGT_MENU_MODAL_INFO"; // 菜单模态框
export const SYS_MGT_MENU_MODAL_INFO_IS_LOADING = "SYS_MGT_MENU__MODAL_INFO_IS_LOADING"; // 模态框显示加载中
export const SYS_MGT_MENU_ADD_ITEM = "SYS_MGT_MENU_ADD_ITEM"; // 新增菜单项
export const SYS_MGT_MENU_DELETE_ITEM = "SYS_MGT_MENU_DELETE_ITEM"; // 删除菜单项
export const SYS_MGT_MENU_EDIT_ITEM = "SYS_MGT_MENU_EDIT_ITEM"; // 删除菜单项

/**
 * 获取菜单列表
 */
export const menuList = (params) => {
  return (dispatch) => {
    dispatch({
      type: SyS_MGT_MENU_TABLE_LOADING,
      isLoading: true,
    });
    RequestService.SystemService.getMenuList(params)
      .then((json) => {
        if (json.code == 200) {
          if (params.parentId === "") {
            // 初始化菜单列表
            dispatch({
              type: SYS_MGT_MENU_LIST_INIT,
              menuList: json.data,
            });
          } else {
            // 展开菜单树
            dispatch({
              type: SYS_MGT_MENU_EXPAND_MENU_ITEM,
              itemList: json.data,
              parentId: params.parentId,
            });
          }
        } else {
          message.error(json.msg);
        }
      })
      .catch((e) => {
        console.error("获取菜单异常:" + e);
        message.error("获取菜单失败");
      })
      .then(() =>
        dispatch({
          type: SyS_MGT_MENU_TABLE_LOADING,
          isLoading: false,
        })
      );
  };
};

/**
 * 设置展开的行号
 * @param expandedRowKeys 展开的行号
 */
export const setExpandedRowKeys = (expandedRowKeys) => {
  return {
    type: SYS_MGT_MENU_EXPANDED_ROW_KEYS,
    expandedRowKeys: expandedRowKeys,
  };
};

/**
 *  设置菜单模态框信息
 * @param menuModal 模态框信息
 */
export const setMenuModalInfo = (menuModalInfo) => {
  return {
    type: SYS_MGT_MENU_MODAL_INFO,
    menuModalInfo: menuModalInfo,
  };
};
/**
 * 添加菜单项
 */
export const addMenuItem = (itemInfo) => {
  return (dispatch) => {
    dispatch({
      type: SYS_MGT_MENU_MODAL_INFO_IS_LOADING,
      isLoading: true,
    });
    RequestService.SystemService.addMenu(itemInfo)
      .then((json) => {
        if (json.code == 200) {
          dispatch({
            type: SYS_MGT_MENU_ADD_ITEM,
            item: json.data,
            parentId: itemInfo.parentId,
          });
          dispatch(
            setMenuModalInfo({
              visible: false,
            })
          );
          message.success("新增成功");
          dispatch(
            menuList({
              menuTreeId: itemInfo.menuTreeId,
              parentId: itemInfo.parentId,
              appNo: itemInfo.appNo,
              // orgType: itemInfo,
            })
          );
        } else {
          message.error(json.msg);
        }
      })
      .catch((e) => {
        console.error("新增菜单异常:" + e);
        message.error("新增失败");
      })
      .then(() =>
        dispatch({
          type: SYS_MGT_MENU_MODAL_INFO_IS_LOADING,
          isLoading: false,
        })
      );
  };
};

/**
 * 删除菜单项
 * @param itemId 菜单项id
 */
export const deleteMenuItem = (itemId) => {
  return (dispatch) => {
    message.loading({ content: "删除中...", key: itemId, duration: 0 });
    RequestService.SystemService.delMenu(itemId)
      .then((json) => {
        if (json.code == 200) {
          dispatch({
            type: SYS_MGT_MENU_DELETE_ITEM,
            itemId: itemId,
          });
          message.success({ content: "删除成功", key: itemId });
        } else {
          message.error({ content: json.msg, key: itemId });
        }
      })
      .catch((e) => {
        console.error("删除菜单项异常:" + e);
        message.error({ content: "删除失败", key: itemId });
      });
  };
};

/**
 * 编辑菜单项
 * @param itemInfo 菜单项信息
 */
export const editMenuItem = (itemInfo) => {
  return (dispatch) => {
    dispatch({
      type: SYS_MGT_MENU_MODAL_INFO_IS_LOADING,
      isLoading: true,
    });
    RequestService.SystemService.editMenu(itemInfo)
      .then((json) => {
        if (json.code == 200) {
          dispatch({
            type: SYS_MGT_MENU_EDIT_ITEM,
            item: itemInfo,
          });
          dispatch(
            setMenuModalInfo({
              visible: false,
            })
          );
          message.success("修改成功");
        } else {
          message.error(json.msg);
        }
      })
      .catch((e) => {
        console.error("编辑菜单异常:" + e);
        message.error("编辑失败");
      })
      .then(() =>
        dispatch({
          type: SYS_MGT_MENU_MODAL_INFO_IS_LOADING,
          isLoading: false,
        })
      );
  };
};
