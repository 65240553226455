
import * as HttpService from "../../service/HttpService";

/**
 *获取用户对账列表
 * @param params 参数列表
 */
export const getUserReconciliationList = (params) => {
    return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/reconciliationManagement/getUserReconciliationList", params);
};
/**
 *获取平台对账列表
 * @param params 参数列表
 */
 export const getPlatformReconciliationList = (params) => {
    return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/reconciliationManagement/getPlatformReconciliationList", params);
};