// 包类型
export const packageType = (type) => {
  switch (type) {
    case 1:
      return "业务目录";
    case 2:
      return "业务包";
    default:
      return "";
  }
};
// 功能组件类型
export const moduleType = (type) => {
  switch (type) {
    case 1:
      return "主页面组件";
    case 10:
      return "子页面组件";
    case 11:
      return "按钮组件";
    default:
      return "";
  }
};
// 业务包类型
export const PackageClassName = {
  MODULE: "module",
  PACKAGE: "package",
  BUTTON: "button",
};

// 动作类型
export const actionType = (type) => {
  type = parseInt(type);
  switch (type) {
    case 1:
      return "主功能窗口";
    case 2:
      return "弹出新窗口";
    case 3:
      return "模式窗口";
    case 10:
      return "JS脚本";
    default:
      return "";
  }
};

// 模态框类型
export const ModalType = {
  ADD: "ADD",
  EDIT: "EDIT",
};
