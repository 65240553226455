import * as HttpService from "../../service/HttpService";

/**
 *获取设备申领单详情
 * @param params 参数
 */
export const applicationFormGetInfo = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/application/form/getInfo", params);
};

/**
 *创建采购申请单
 * @param params 参数
 */
export const savePurchaseOrder = (data) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/purchaseOrder/savePurchaseOrder", data);
};

/**
 *采购申请单详情
 * @param params 参数
 */
export const getPurchaseOrderDetailById = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/purchaseOrder/getPurchaseOrderDetailById", params);
};

/**
 *采购申请单列表
 * @param params 参数
 */
export const pagePurchaseOrder = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/purchaseOrder/pagePurchaseOrder", params);
};

/**
 *设备申领列表
 * @param params 参数
 */
export const applicationFormQueryPage = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/application/form/queryPage", params);
};

/**
 *审核申领单
 * @param params 参数
 */
export const applicationFormExamine = (data) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/application/form/examine", data);
};

/**
 *填写物流信息
 * @param params 参数
 */
export const logisticsSaveOrUpdate = (data) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/application/logistics/saveOrUpdate", data);
};

/**
 *申领单关联的物流信息
 * @param params 参数
 */
export const applicationLlogisticsList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/application/logistics/list", params);
};

/**
 *申领单发货
 * @param params 参数
 */
export const applicationFormDeliverGoods = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/application/form/deliverGoods", params);
};


/**
 *根据仓库id获取申领单列表
 * @param params 参数
 */
export const applicationFormGetDepotApply = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/application/form/getDepotApply", params);
};

/**
 *审批采购单
 * @param params 参数
 */
export const operPurchaseOrderById = (data) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/purchaseOrder/operPurchaseOrderById", data);
};

/**
 *出入库明细列表
 * @param params 参数
 */
export const depotRecordPage = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/depot/record/page", params);
};

/**
 *库存状况列表
 * @param params 参数
 */
export const pageStatisticsDepotDetail = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/depotDetail/pageStatisticsDepotDetail", params);
};

/**
 *下载采购单
 * @param params 参数
 */
export const exportPurchaseOrder = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/purchaseOrder/exportPurchaseOrder", params);
};

/**
 *所有的仓库数据-树状
 * @param params 参数
 */
export const assetDepotAllDepot = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/assetDepot/allDepot", params);
};
/**
 *运维设备查询
 * @param params 参数
 */
export const getOpsDevivePage = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/ops/device/page", params);
};
/**
 *用户设备详情
 * @param params 参数
 */
export const getDetailList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/ops/device/detailList", params);
};

/**
 *用户设备详情
 * @param params 参数
 */
export const getLogisticsDetail = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-iesms-common/logistics/getLogisticsDetail", params);
};

/**
 *采购单/申领单电池入库记录
 * @param params 参数
 */
export const getMaterialRecord = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/material/asset/getMaterialRecord", params);
};

/**
 *出入库设备详情查询
 * @param params 参数
 */
export const recordDeviceList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/depot/record/deviceList", params);
};

/**
 *库存状况设备详情列表
 * @param params 参数
 */
export const pageDepotDetail = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/depotDetail/pageDepotDetail", params);
};
