

/**
 *  展开行
 * @param expendedId 展开的行id
 * @param sonList 子集
 * @param originList 原集合
 * @param flag 结束标志位
 */
export const expandedRow = (expendedId, sonList, originList, flag = [0, 0]) => {
    for(var i = 0; i < originList.length; i++){
        flag[1]++;
        if(flag[0] === 1){
            return;
        }
        if(originList[i].id === expendedId){
            originList[i].children = sonList;
            flag[0] = 1;
            break;
        }else if(originList[i].children != null && originList[i].children.length > 0){
            expandedRow(expendedId, sonList, originList[i].children, flag);
        }
    }
}

/**
 * 新增行数据
 * @param originList 原集合
 * @param parentId 父id
 * @param flag 结束标志
 */
export const addRow = (originList, parentId, flag = [0, 0]) => {
    var i = 0;
    while(i < originList.length){
        flag[1]++;
        if(flag[0] === 1){
            return;
        }
        if(originList[i].id === parentId){
            originList[i].hasChildren = true;
            flag[0] = 1;
            break;
        }else if(originList[i].children != null && originList[i].children.length > 0){
            addRow(originList[i].children, parentId, flag);
        }
        i++;
    }
}


/**
 * 删除行数据
 * @param rowId 行id
 * @param originList 原集合
 * @param parent 父对象 (仅在递归时使用，调用时传入null)
 * @param flag 结束标志
 */
export const deleteRow= (rowId, originList, parent = null, flag = [0, 0]) => {
    var i = originList.length;
    while(i--){
        flag[1]++;
        if(flag[0] === 1){
            return;
        }
        if(originList[i].id === rowId){
            originList.splice(i, 1);
            flag[0] = 1;
            if(originList.length < 1){
                if(parent != null){
                    parent.hasChildren = false;
                }
            }
            break;
        }else if(originList[i].children != null && originList[i].children.length > 0){
            deleteRow(rowId, originList[i].children, originList[i], flag);
        }
    }
}

/**
 * 编辑行
 * @param rowData 行数据
 * @param originList 原集合
 * @param flag 结束标志位,
 */
export const editRow = (rowData, originList, flag = [0, 0]) => {
    var i = originList.length;
    while(i--){
        flag[1]++;
        if(flag[0] === 1){
            return;
        }
        if(rowData.id === originList[i].id){
            originList[i] = {
                ...originList[i],
                ...rowData
            };
            flag[0] = 1;
            break;
        }else if(originList[i].children != null && originList[i].children.length > 0){
            editRow(rowData, originList[i].children, flag);
        }
    }
}
