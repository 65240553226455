import { combineReducers } from "redux";
import systemReducer from "../views/systemSetting/reducer";
// import smartEnergyData from "../components/projects/smart_energy/reducer/index";
import userData from "./user.reducer";

const appReducer = combineReducers({
  systemReducer,
  // smartEnergyData,
  userData,
});

/**
 * 根reducer
 * @param state
 * @param action
 */
const rootReducer = (state, action) => {
  if (action.type == "USER_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
