import * as HttpService from "../../service/HttpService";

/**
 * 启用/禁用
 * @param params 参数列表
 */
export const billingStatus = (params) => {
  return HttpService.put_param(HttpService.DOMAIN + "/gaea-cabinet/billingTmpl/status", params);
};

/**
 * 启用/禁用  计费套餐审核 提交审核 
 * @param params 参数列表
 * http://yapi.ebike-charge.com:6060/project/437/interface/api/15394
 */
export const submitForAudit = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/billingPackageReviewRecord/submitForAudit", params);
};
/**
 * 启用/禁用  计费套餐审核 审核
 * @param params 参数列表
 * http://yapi.ebike-charge.com:6060/project/437/interface/api/15406
 */
export const performAudit = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/billingPackageReviewRecord/performAudit", params);
};
/**
 * 计费套餐审核-获取审核详情
 * @param params 参数列表
 * http://yapi.ebike-charge.com:6060/project/437/interface/api/15398
 */
export const getAuditInformation = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/billingPackageReviewRecord/getAuditInformation", params);
};
/**
 * 计费套餐审核-获取审核列表
 * @param params 参数列表
 * http://yapi.ebike-charge.com:6060/project/437/interface/api/15402
 */
export const getAuditList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/billingPackageReviewRecord/getAuditList", params);
};
/**
 * 计费套餐审核-获取审核记录
 * @param params 参数列表
 * http://yapi.ebike-charge.com:6060/project/437/interface/api/15410
 */
export const getAuditRecords = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/billingPackageReviewRecord/getAuditRecords", params);
};
/**
 * 计费套餐-设备材料
 * @param params 参数列表
 * http://yapi.ebike-charge.com:6060/project/484/interface/api/15833
 */
export const listMaterial = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/material/listMaterial", params);
};
/**
 * 计费套餐-获取二级及托管仓库列表
 * @param params 参数列表
 * http://yapi.ebike-charge.com:6060/project/484/interface/api/16237
 */
export const listSecAssetDepot = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/assetDepot/listSecAssetDepot", params);
};
