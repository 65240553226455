import * as Action from "../action/index.action";

const initState = {
  userInfo: {}, // 用户信息
  projectList: [], // 项目列表
  // menuList: [], // 菜单列表
  currentProject: {
    // 当前项目id
    id: "",
    index: null,
    indexPath: "",
    btnList: [],
  },
  header: {
    collapsed: false,
  },
};

/**
 * 用户信息reducer
 */
const userReducer = (state = initState, action) => {
  switch (action.type) {
    case Action.COMMON_SET_USER_INFO: // 设置用户信息
      return { ...state, userInfo: action.user };
    case Action.COMMON_SET_PROJECT_LIST: // 设置项目信息
      return { ...state, projectList: action.projectList };
    case Action.COMMON_SET_MENU_LIST: // 设置菜单信息
      return { ...state, menuList: action.menuList };
    case Action.COMMON_SET_CURRENT_PROJECT: // 设置当前项目信息
      return { ...state, currentProject: { ...state.currentProject, ...action.projectInfo } };
    case Action.COMMON_HEADER_TOGGLE_SIDEBAR: // toggle收放左侧sidebar
      return {
        ...state,
        header: {
          collapsed: !state.header.collapsed,
        },
      };
    case Action.EDIT_USER_PROFILE: //编辑用户基本信息
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.profile,
        },
      };
    default:
      return state;
  }
};

export default userReducer;
