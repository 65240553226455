import * as Action from "../action/sys_mgt_business_package.action";
import * as tableUtil from "./tableUtil";
const initState = {
  packageList: [], // 业务包列表
  isLoading: false, // 是否显示加载中
  expandedRowKeys: [], // 展开的行号
  packageModalInfo: {
    // 业务包模态框信息
    visible: false,
    title: "",
    isLoading: false,
    info: {},
  },
  moduleModalInfo: {
    // 功能组件模态框信息
    visible: false,
    title: "",
    isLoading: false,
    info: {},
  },
  moduleTree: [], // 组件树
};

const packageReducer = (state = initState, action) => {
  const packageList = [...state.packageList];
  switch (action.type) {
    case Action.SYS_MGT_BUSINESS_PACKAGE_LIST_INIT: // 初始化列表数据
      return { ...state, packageList: action.data, expandedRowKeys: [] };
    case Action.SYS_MGT_BUSINESS_PACKAGE_TABLE_LOADING: // 控制显示加载中
      return { ...state, isLoading: action.isLoading };
    case Action.SYS_MGT_BUSINESS_PACKAGE_EXPANDED_ROW_KEYS: // 控制展开的行号
      return { ...state, expandedRowKeys: action.expandedRowKeys };
    case Action.SYS_MGT_BUSINESS_PACKAGE_PACKAGE_MODAL_INFO_IS_LOADING: // 业务包模态框是否加载中
      return { ...state, packageModalInfo: { ...state.packageModalInfo, isLoading: action.isLoading } };
    case Action.SYS_MGT_BUSINESS_PACKAGE_PACKAGE_MODAL_INFO: // 业务包模态框信息
      return {
        ...state,
        packageModalInfo: {
          ...state.packageModalInfo,
          ...action.packageModalInfo,
        },
      };
    case Action.SYS_MGT_BUSINESS_PACKAGE_LIST_EXPAND: // 展开父级元素
      tableUtil.expandedRow(action.packageId, action.dataList, packageList);
      // 设置新的数据默认不展开
      const keysArray = action.dataList.map((pack) => {
        return pack.id;
      });
      // 从expandedRowKeys中剔除新获取数据的key
      const newCheckedKeys = state.expandedRowKeys.filter((key) => keysArray.indexOf(key) === -1);
      return {
        ...state,
        expandedRowKeys: [...newCheckedKeys, action.packageId],
        packageList: packageList,
      };
    case Action.SYS_MGT_BUSINESS_PACKAGE_ADD_PACKAGE: //业务包新增
      tableUtil.addRow(packageList, action.parentId);
      return {
        ...state,
        packageList: packageList,
      };
    case Action.SYS_MGT_BUSINESS_PACKAGE_DELETE_PACKAGE: //业务包删除
      tableUtil.deleteRow(action.packageId, packageList);
      return {
        ...state,
        packageList: packageList,
      };
    case Action.SYS_MGT_BUSINESS_PACKAGE_MODULE_MODAL_INFO: // 功能组件模态框
      return {
        ...state,
        moduleModalInfo: {
          ...state.moduleModalInfo,
          ...action.moduleModalInfo,
        },
      };
    case Action.SYS_MGT_BUSINESS_PACKAGE_MODULE_MODAL_INFO_IS_LOADING: //功能组件模态框 确认加载
      return { ...state, moduleModalInfo: { ...state.moduleModalInfo, isLoading: action.isLoading } };
    case Action.SYS_MGT_BUSINESS_PACKAGE_EDIT_PACKAGE: // 业务包编辑
      tableUtil.editRow(action.pack, packageList);
      return {
        ...state,
        packageList: packageList,
      };
    case Action.SYS_MGT_BUSINESS_PACKAGE_MODULE_TREE_INIT: // 初始化功能组件树
      if (action.isLoad) {
        return {
          ...state,
          moduleTree: [...state.moduleTree, ...action.moduleTree],
        };
      } else {
        return {
          ...state,
          moduleTree: [...action.moduleTree],
        };
      }

    default:
      return state;
  }
};

export default packageReducer;
