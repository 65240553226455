import * as HttpService from "../../service/HttpService";
/**
 * 投诉建议分页查询
 * @param params 参数列表
 */
export const getcomplaintList = (params) => {
    return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/complaint/page", params);
};
/**
 * 投诉建议详情
 * @param complaintNo 编号
 */
export const getComplaintInfo = (params) => {
    return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/complaint/getComplaintInfo", params);
};
/**
 * 投诉建议回复
 * @param complaintNo 编号
 * @param replyContent 回复信息
 */
export const replyComplaint = (params) => {
    return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/complaint/reply", params);
};
/**
 * 获取故障登记分页列表
 */
export const getpageOpsFaultRegistration = (params) => {
    return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/elecFaultRegistration/pageOpsFaultRegistration", params);
};
//新增
export const saveOpsFaultRegistration = (params) => {
    return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecFaultRegistration/saveOpsFaultRegistration", params);
};
//修改
export const updateOpsFaultRegistrationById = (params) => {
    return HttpService.put_param(HttpService.DOMAIN + "/gaea-cabinet/elecFaultRegistration/updateOpsFaultRegistrationById", params);
};
//操作
export const operRegistration = (params) => {
    return HttpService.put_param(HttpService.DOMAIN + "/gaea-cabinet/elecFaultRegistration/operRegistration",params);
}
//查询
export const getOpsFaultRegistrationById = (params) => {
    return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/elecFaultRegistration/getOpsFaultRegistrationById",params);
}
//查询
export const deleteOpsFaultRegistrationByIds = (params) => {
    return HttpService.del(HttpService.DOMAIN + "/gaea-cabinet/elecFaultRegistration/deleteOpsFaultRegistrationByIds",params);
}
