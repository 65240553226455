import * as HttpService from "../../service/HttpService";
/**
 *分页查询集团列表
 * @param params 参数列表
 */
export const getGroupCustPage = (params) => {
    return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/groupCust/getGroupCustPage", params);
};
/**
 *新增集团客户
 * @param params 参数列表
 */
export const saveGroupCust = (params) => {
    return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/groupCust/saveGroupCust", params);
};
/**
 *删除集团客户
 * @param params 参数列表
 */
export const deleteGroupCust = (params) => {
    return HttpService.del(HttpService.DOMAIN + "/gaea-cabinet/groupCust/deleteGroupCust", params);
};
/**
 *分页查询集团员工列表
 * @param params 参数列表
 */
export const getGroupUserPage = (params) => {
    return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/groupCustReal/getGroupUserPage", params);
};
/**
 *新增集团员工
 * @param params 参数列表
 */
export const saveGroupCustReal = (params) => {
    return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/groupCustReal/saveGroupCustReal", params);
};
/**
 *编辑集团员工
 * @param params 参数列表
 */
export const updateGroupCustReal = (params) => {
    return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/groupCustReal/updateGroupCustReal", params);
};

/**
 *删除集团员工
 * @param params 参数列表
 */
export const deletepCustReal = (params) => {
    return HttpService.del(HttpService.DOMAIN + "/gaea-cabinet/groupCustReal/deleteGroupCustReal", params);
};
/**
 *获取充值列表
 * @param params 参数列表
 */
export const getGroupAccountRechargePage = (params) => {
    return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/groupCustRecharge/getGroupAccountRechargePage", params);
};
/**
 *获取充值余额
 * @param params 参数列表
 */
export const getRechargeBalance = () => {
    return HttpService.get(HttpService.DOMAIN + "/gaea-cabinet/groupCustRecharge/getRechargeBalance");
};
export const recharge = (params) => {
    return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/groupCust/recharge",params);
};
/**
 *分页查询集团代付列表 刘亮林
 http://yapi.ebike-charge.com:6060/project/437/interface/api/16197
 * @param params 参数列表
 */
 export const getGroupCustPayReviewPage = (params) => {
    return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/groupCustPayReview/getGroupCustPayReviewPage", params);
};
/**
 *集团代付审核 刘亮林
 http://yapi.ebike-charge.com:6060/project/437/interface/api/16201
 * @param params 参数列表
 */
 export const groupCustPayReviewReview = (params) => {
    return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/groupCustPayReview/review", params);
};