import React, { useEffect, useState } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import RouteGuard from "./RouteGuard";
import loadable from "../utils/loadable";

const Login = loadable(() => import("../components/Login"));
const AppContainer = loadable(() => import("../container/AppContainer"));
const UserProtocol = loadable(() => import("../components/UserProtocol"));
const MRoute = (props) => {
  useEffect(() => {
    document.getElementById("loading").style.display = "none";
  }, []);
  return (
    <Switch>
      <Route exact path={"/protocol"} component={UserProtocol} />
      <Route exact path={"/login"} component={Login} />
      <Route path="/app">
        <RouteGuard>
          <AppContainer />
        </RouteGuard>
      </Route>
      <Redirect to="/login" />
    </Switch>
  );
};

export default withRouter(MRoute);
