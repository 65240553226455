import * as SystemService from "./systemService.js";
import * as OperationService from "./OperationService";
import * as OrderService from "./OrderService";
import * as dataViewService from "./dataViewService";
import * as DisasterPreventionService from "./disasterPreventionService";
import * as OrderChargeService from "./orderChargeService";
import * as SurveyWorkOrderService from "./surveyWorkOrderService.js";
import * as BillingPackageService from "./billingPackageService";
import * as FinancialMgtService from "./financialService";
import * as RedPacketService from "./redpacketService.js";
import * as CustomerServiceCenter from "./custmerServiceCenter.js";
import * as ArchivesMgtService from "./ArchivesMgtService";
import * as InventoryService from "./inventoryService.js";
import * as CommonService from "./CommonService.js";
import * as GroupMgtService from "./GroupMgtService.js";
/**
 *
 */
const RequestService = {
  SystemService, //系统参数
  OperationService, //换电柜管理
  OrderService, //订单管理
  dataViewService, //数据概览
  DisasterPreventionService, //防灾应急
  OrderChargeService, //有序充电
  SurveyWorkOrderService, //勘查工单
  BillingPackageService,//计费套餐
  FinancialMgtService,//财务管理
  RedPacketService, //红包活动
  CustomerServiceCenter,//客服中心
  ArchivesMgtService,//设备档案相关接口
  InventoryService, //设备出入库
  GroupMgtService,//集团管理
  CommonService
};
export default RequestService;
