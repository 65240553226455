import * as HttpService from "../../service/HttpService";

// 数据概览
/*
 *站点概况及客户规模数据
 */
export const getElecStaUserInfo = () => {
  return HttpService.get(HttpService.DOMAIN + "/gaea-cabinet/elecDataOverview/getElecStaUserInfo");
};
export const getElecInComExchange = () => {
  return HttpService.get(HttpService.DOMAIN + "/gaea-cabinet/elecDataOverview/getElecInComExchange");
};
export const getElecDataOverCellOrderList = () => {
  return HttpService.get(HttpService.DOMAIN + "/gaea-cabinet/elecDataOverview/getElecDataOverCellOrderList");
};
export const getPowerChangCount = () => {
  return HttpService.get(HttpService.DOMAIN + "/gaea-cabinet/elecDataOverview/getPowerChangCount");
};
/*
 *虚拟电厂
 */
export const getElecVirtualPowerPlantData = (params) => {
  return HttpService.getNormal(
    HttpService.DOMAIN + "/gaea-cabinet/elecDataOverview/getElecVirtualPowerPlantData",
    params
  );
};
/*
 *虚拟电厂
 */
export const getElecOverConduitData = () => {
  return HttpService.get(HttpService.DOMAIN + "/gaea-cabinet/elecDataOverview/getElecOverConduitData");
};
/*
 *资产现状
 */
export const getElecAssetStatusData = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/elecDataOverview/getElecAssetStatusData", params);
};
/*
 *地图数据
 */
export const getElecDataOverMapData = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/elecDataOverview/getElecDataOverMapData", params);
};
/*
 * 运营分析--总览统计
 */
export const getOperAnalysisStatOverVo = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/operAnalysis/getOperAnalysisStatOverVo", data);
};
/*
 * 运营分析--注册人数、购买套餐人数曲线
 */
export const getOperAnalysisStatRegisterCurve = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/operAnalysis/getOperAnalysisStatRegisterCurve", data);
};
/*
 * 套餐收入分析--总览
 */
export const getOperAnalysisPackageData = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/operAnalysis/getOperAnalysisPackageData", data);
};
/*
 * 套餐收入分析--套餐收入曲线
 */
export const getPackageRevenueCurve = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/operAnalysis/getPackageRevenueCurve", data);
};
/*
 * 用户习惯换电分析--总览
 */
export const getOperAnalysisStatHdData = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/operAnalysis/getOperAnalysisStatHdData", data);
};
/*
 * 用户习惯换电分析--存在换电站的区域
 */
export const getCbikeAdcode = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/operAnalysis/getCbikeAdcode", data);
};
/*
 * 用户习惯换电分析--换电站换电次数排名
 */
export const getOperAnalysisStatStationList = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/operAnalysis/getOperAnalysisStatStationList", data);
};
/*
 * 活动效果分析--总览
 */
export const getOperAnalysisActivityEffect = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/operAnalysis/getOperAnalysisActivityEffect", data);
};
/*
 * 活动效果分析--列表
  http://yapi.ebike-charge.com:6060/project/437/interface/api/15689
 */
export const getOperAnalysisActivityEffectPage = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/operAnalysis/getOperAnalysisActivityEffectPage", data);
};
/*
 * 用户换电习惯分析--换电次数曲线
  http://yapi.ebike-charge.com:6060/project/437/interface/api/15697
 */
export const getOperAnalysisHdCountCurve = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/operAnalysis/getOperAnalysisHdCountCurve", data);
};
