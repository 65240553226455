import * as EBikeService from "../service/EBikeService";
import { message } from "antd";

export const COMMON_SET_USER_INFO = "COMMON_SET_USER_INFO"; // 设置用户信息
export const COMMON_SET_PROJECT_LIST = "COMMON_SET_PROJECT_LIST"; // 设置项目列表
export const COMMON_SET_MENU_LIST = "COMMON_SET_MENU_LIST"; // 设置项目列表
export const COMMON_SET_CURRENT_PROJECT = "COMMON_SET_CURRENT_PROJECT"; // 设置当前项目信息
export const COMMON_HEADER_TOGGLE_SIDEBAR = "HEADER_TOGGLE_SIDEBAR";
export const USER_LOGOUT = "USER_LOGOUT"; // 用户登出
export const EDIT_USER_PROFILE = "EDIT_USER_PROFILE"; // 编辑用户基本信息

/**
 * 用户登出
 */
export const userLogout = () => {
  return {
    type: USER_LOGOUT,
  };
};

/**
 * 获取用户信息
 */
export const getUserInfo = () => {
  return (dispatch) => {
    EBikeService.getUserInfo()
      .then((json) => {
        if (json.code == 200) {
          dispatch({
            type: COMMON_SET_USER_INFO,
            user: { ...json.data },
          });
          sessionStorage.setItem("codeName", json.data.adName);
          sessionStorage.setItem("codeNo", json.data.adCode);
        } else {
          message.error(json.msg);
        }
      })
      .catch((e) => {
        console.error("获取用户信息异常:" + e);
        message.error("获取信息失败");
      });
  };
};
/**
 * 设置项目列表
 */
export const getProjectList = (url) => {
  return (dispatch, getState) => {
    EBikeService.getMenuListByProjectId("cabinet-webapps-console", "CABINET_MENU_TREE")
      .then((json) => {
        if (json.code == 200) {
          let projectList = json.data;
          dispatch({
            type: COMMON_SET_PROJECT_LIST,
            projectList: projectList,
          });
          let project;
          if (url != "/app") {
            project = projectList.find((item) => url.startsWith(item.menuItemPath));
          }
          project = project || projectList[0];
          let btnList = getBtnList(project);
          let Item = findIndexUrl(project.children);
          let homePath = Item
            ? Item.menuItemType === "10" && Item.children.length > 0
              ? Item.children[0].menuItemPath
              : Item.menuItemPath
            : "/app";
          dispatch(setCurrentProject({ id: project.id, indexPath: project.menuItemPath, btnList: btnList }));
        } else {
          message.error(json.msg);
        }
      })
      .catch((e) => {
        console.error("获取项目信息异常:" + e);
        message.error("获取项目列表失败");
      });
  };
};

// /**
//  * 通过项目id获取菜单列表
//  * @param projectId 项目id
//  * @param isReIndex 是否重新定位索引
//  */
export const getMenuList = (projectId, currentPath) => {
  //   return (dispatch, getState) => {
  //     //ZHYNGLDEV   暂时修改  好测试
  //     const currentUser = getState().userData.userInfo.userNo;
  //     if (!currentUser || currentUser == "ZHYNGLDEV") {
  //       dispatch({
  //         type: COMMON_SET_MENU_LIST,
  //         menuList: smartEnergyMenu,
  //       });
  //       if (smartEnergyMenu.length > 0) {
  //         let item = findUrl(smartEnergyMenu, currentPath);
  //         let homeItem = smartEnergyMenu[0].children.find(
  //           (item) => item.leaf == true
  //         );
  //         let homePath = homeItem ? homeItem.moduleActionPath : "/app";
  //         let path = item ? currentPath : homePath;
  //         dispatch(setCurrentProject({ indexPath: path, homePath: homePath }));
  //       }
  //       return;
  //     }
  //     EBikeService.getMenuListByProjectId(projectId)
  //       .then((json) => {
  //         if (json.code == 0) {
  //           dispatch({
  //             type: COMMON_SET_MENU_LIST,
  //             menuList: json.data,
  //           });
  //           if (json.data.length > 0) {
  //             let item = findUrl(json.data, currentPath);
  //             let homeItem = json.data[0].children.find(
  //               (item) => item.leaf == true
  //             );
  //             let homePath = homeItem
  //               ? homeItem.moduleActionPath
  //               : "/app/not-found";
  //             let path = item ? currentPath : homePath;
  //             dispatch(
  //               setCurrentProject({ indexPath: path, homePath: homePath })
  //             );
  //           }
  //         } else {
  //           message.error(json.msg);
  //         }
  //       })
  //       .catch((e) => {
  //         console.error("获取菜单列表异常:" + e);
  //         message.error("获取菜单信息失败");
  //       });
  //   };
};

/**
 * 设置当前项目
 * @param projectInfo 项目信息
 */
export const setCurrentProject = (projectInfo) => {
  return {
    type: COMMON_SET_CURRENT_PROJECT,
    projectInfo: projectInfo,
  };
};

/**
 * 切换项目
 * @param projectId
 */
export const changeProject = (projectId) => {
  return setCurrentProject({ id: projectId });
};

/**
 * toogle sidebar
 */
export const toggleSideBar = () => {
  return {
    type: COMMON_HEADER_TOGGLE_SIDEBAR,
  };
};

/**
 * 编辑基本信息
 * @param profile
 */
export const editProfile = (profile) => {
  return {
    type: EDIT_USER_PROFILE,
    profile: profile,
  };
};

/**
 * 查找菜单目录中的url
 * @param menuList 菜单列表
 * @param url 当前路径
 */
const findUrl = (menuList, url) => {
  return menuList.find((item) => {
    if (item.moduleActionPath == url) {
      return true;
    }
    if (item.leaf == false && item.children) {
      return findUrl(item.children, url);
    } else {
      return false;
    }
  });
};
const findIndexUrl = (menuList) => {
  return menuList.find((item) => {
    if (item.leaf == false && item.children && item.menuItemType === "10") {
      return findIndexUrl(item.children);
    } else if (item.menuItemType === "20") {
      return item;
    } else {
      return false;
    }
  });
};
/**
 * 查找菜单目录中的按钮
 * @param menuList 菜单列表
 */
const getBtnList = (menuList) => {
  let newList = [];
  const initFilterMenuList = (list) => {
    for (let i = 0; i < list.length; i++) {
      if ((list[i].moduleCode && list[i].moduleType.value === 11) || (list[i].menuItemCode && list[i].menuItemType === "30")) {
        newList.push(list[i]);
      }
      if (list[i].hasChildren && list[i].children.length > 0) {
        initFilterMenuList(list[i].children);
      }
    }
  };
  initFilterMenuList(menuList.children);
  return newList;
};
