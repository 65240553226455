import * as HttpService from "../../service/HttpService";
/** 红包活动相关接口 */

export const getAuditList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/redPacketActivityReview/getAuditList", params);
};

export const getAuditRecords = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/redPacketActivityReview/getAuditRecords", params);
};

export const getAuditInformation = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/redPacketActivityReview/getAuditInformation", params);
};

export const performAudit = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/redPacketActivityReview/performAudit", params);
};

export const selectRedPacketTmplPage = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/redPacketTmpl/selectRedPacketTmplPage", params);
};

export const saveRedPacketTmpl = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/redPacketTmpl/saveRedPacketTmpl", params);
};

export const updateRedPacketTmpl = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/redPacketTmpl/updateRedPacketTmpl", params);
};

export const redPacketSubmitForAudit = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/redPacketActivityReview/submitForAudit", params);
};

/**
 * 用户红包列表信息
 */
export const selectRedPacketPage = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/redPacketTmpl/selectRedPacketPage", params);
};
