import * as Action from "../action/sys_mgt_menu.action";
import * as tableUtil from "./tableUtil";
const initState = {
  menuList: [], //组织单位列表
  isLoading: false, // 是否显示加载中
  expandedRowKeys: [], // 展开的行号
  menuModalInfo: {
    visible: false,
    title: "",
    isLoading: false,
  },
};

// 菜单reducer
const menuReducer = (state = initState, action) => {
  const menuList = [...state.menuList];
  switch (action.type) {
    case Action.SYS_MGT_MENU_LIST_INIT: // 菜单列表初始化
      return {
        ...state,
        expandedRowKeys: [],
        menuList: action.menuList,
      };
    case Action.SyS_MGT_MENU_TABLE_LOADING: // 数据表加载中
      return { ...state, isLoading: action.isLoading };
    case Action.SYS_MGT_MENU_EXPANDED_ROW_KEYS: // 控制展开的行号
      return { ...state, expandedRowKeys: action.expandedRowKeys };
    case Action.SYS_MGT_MENU_EXPAND_MENU_ITEM: // 展开菜单项
      tableUtil.expandedRow(action.parentId, action.itemList, menuList);
      // 设置新的数据默认不展开
      const keysArray = action.itemList.map((item) => {
        return item.id;
      });
      // 从expandedRowKeys中剔除新获取数据的key
      const newCheckedKeys = state.expandedRowKeys.filter((key) => keysArray.indexOf(key) === -1);
      return {
        ...state,
        expandedRowKeys: [...newCheckedKeys, action.parentId],
        menuList: menuList,
      };
    case Action.SYS_MGT_MENU_MODAL_INFO: // 设置模态框信息
      return { ...state, menuModalInfo: action.menuModalInfo };
    case Action.SYS_MGT_MENU_MODAL_INFO_IS_LOADING: // 模态框显示加载中
      return { ...state, menuModalInfo: { ...state.menuModalInfo, isLoading: action.isLoading } };
    case Action.SYS_MGT_MENU_ADD_ITEM: // 新增菜单项
      tableUtil.addRow(menuList, action.parentId);
      return {
        ...state,
        menuList: menuList,
      };
    case Action.SYS_MGT_MENU_DELETE_ITEM: // 删除菜单项
      tableUtil.deleteRow(action.itemId, menuList);
      return {
        ...state,
        menuList: menuList,
      };
    case Action.SYS_MGT_MENU_EDIT_ITEM: // 编辑菜单项
      tableUtil.editRow(action.item, menuList);
      return {
        ...state,
        menuList: menuList,
      };
    default:
      return state;
  }
};

export default menuReducer;
