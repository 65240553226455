import * as HttpService from "../../service/HttpService";
/* 树组件 */
export async function getTreeList(params) {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-system/orgAnddepts/org-dept-mix-lazy-tree", params);
}
/**
 *批量获取字典项
 * @param params 参数列表
 */
 export const getBatchCodeList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-system/feign/query",{codeSortNoList:params,projectCode:"CBIKE_CODE"});
};
/**
 *获取仓库管理员列表
 * @param params 参数列表
 */
 export const getWarehousemanList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-system/users/drop-down-box");
};
/**
 * 获取所属区域树
 * @param params 参数列表
 */
export const getAdCodeTree = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/adCode/tree",params);
};