import * as HttpService from "../../service/HttpService";



/**
 * 获取勘查工单列表
 * http://yapi.ebike-charge.com:6060/project/437/interface/api/12286
 */
export const getSurveyOrderList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/workOrder/getSurveyOrderList", params);
};
/**
 * 获取勘查工单详情
 * 获取勘查工单详情
 */
export const getSurveyOrderDetails = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/workOrder/getSurveyOrderDetails", params);
};
/**
 * 导出勘查工单列表
 * http://yapi.ebike-charge.com:6060/project/437/interface/api/12282
 */
export const exportSurveyWorkOrderList = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/workOrder/exportSurveyWorkOrderList", params);
};
/**
 * 审核勘查工单
 * http://yapi.ebike-charge.com:6060/project/437/interface/api/12358
 */
export const reviewSurveyWorkOrder = (params) => {
  return HttpService.put_param(HttpService.DOMAIN + "/gaea-cabinet/workOrder/reviewSurveyWorkOrder", params);
};






