import * as HttpService from "../../service/HttpService";

/**
 * 获取设备材料
 * @param params 参数列表
 */
export const getPageMaterial = (params) => {
    return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/material/pageMaterial", params);
};
/**
* 通过ID更新设备材料
* @param params 参数列表
*/
export const updateMaterial = (params) => {
    return HttpService.put_param(HttpService.DOMAIN + "/gaea-cabinet/material/updateMaterial", params);
};
/**
* 通过ID查看设备材料详情
* @param params 参数列表
*/
export const getMaterialById = (params) => {
    return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/material/getMaterialById", params);
};
/**
* 通过ID删除设备材料详情
* @param params 参数列表
*/
export const deleteMaterialById = (params) => {
    return HttpService.del(HttpService.DOMAIN + "/gaea-cabinet/material/deleteMaterialById", params);
};
/**
* 新增设备材料
* @param params 参数列表
*/
export const saveMaterial = (params) => {
    return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/material/saveMaterial", params);
};
/**
* 新增仓库
* @param params 参数列表
*/
export const saveAssetDepot = (params) => {
    return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/assetDepot/saveAssetDepot", params);
};
/**
* 查询仓库列表
* @param params 参数列表
*/
export const getPageAssetDepot = (params) => {
    return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/assetDepot/pageAssetDepot", params);
};


/**
* 查询仓库详情
* @param params 参数列表
*/
export const getAssetDepotById = (params) => {
    return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/assetDepot/getAssetDepotById", params);
};
/**
* 修改仓库详情
* @param params 参数列表
*/
export const updateAssetDepot = (params) => {
    return HttpService.put_param(HttpService.DOMAIN + "/gaea-cabinet/assetDepot/updateAssetDepot", params);
};
/**
* 删除仓库详情
* @param params 参数列表
*/
export const deleteAssetDepotById = (params) => {
    return HttpService.del(HttpService.DOMAIN + "/gaea-cabinet/assetDepot/deleteAssetDepotById", params);
};

/**
* 资产管理-电池
* @param params 参数列表
*/
export const pageDeviceUseDetail = (params) => {
    return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/depotDetail/pageDeviceUseDetail", params);
};

/**
* 资产管理-电动车
* @param params 参数列表
*/
export const pageMobileDeviceUseDetail = (params) => {
    return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/depotDetail/pageMobileDeviceUseDetail", params);
};

/**
* 资产管理-电池-使用情况
* @param params 参数列表
*/
export const getDeviceUseDesc = (params) => {
    return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/depotDetail/getDeviceUseDesc", params);
};

/**
* 资产管理-电动车-使用情况
* @param params 参数列表
*/
export const getMobileDeviceUseDesc = (params) => {
    return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/depotDetail/getMobileDeviceUseDesc", params);
};